<template>
  <v-container class="px-6 d-block" fluid>
    <div class="d-flex align-center mb-6">
      <v-icon size="15" color="#4ab762">mdi-home</v-icon>
      <v-breadcrumbs :items="breadcrumbs" class="pa-0 ml-2">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>

    <v-row>
      <v-col cols="12" md="6">
        <v-card
          class="rounded-lg"
          style="box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px"
        >
          <v-card-title style="color: #4ab762" class="subtitle-1 py-2 d-block">
            Ubah Password
            <div class="caption grey--text text--darken-1">
              Lengkapi form dibawah ini
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: calc(100vh - 306px); overflow-y: auto">
            <v-alert dense text type="info" color="#4ab762" class="caption">
              Tekan tombol <b>Kirim Kode</b>, lalu cek email Anda dan masukkan
              kode OTP pada form yang telah tersedia
            </v-alert>

            <v-form
              ref="form-password"
              v-model="valid"
              lazy-validation
              @keyup.native.enter="doSave"
            >
              <div class="subtitle-2">
                Kode OTP
                <small style="color: #e74c3c"><i>*wajib diisi</i></small>
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  solo
                  autocomplete="off"
                  color="#4ab762"
                  type="text"
                  placeholder="Tulis disini"
                  class="rounded-l-lg rounded-r-0 mb-3"
                  :disabled="loading"
                  v-model="form.code"
                  :error-messages="validation.code"
                  @keyup="validation.code = ''"
                  :rules="[
                    (v) => !!v || `Kode OTP tidak boleh kosong`,
                    (v) => (v && v.length == 6) || 'Kode OTP harus 6 karakter',
                  ]"
                >
                  <template v-slot:message="{ message }">
                    <v-tooltip top max-width="250" color="#e74c3c">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          size="22"
                          class="mr-2 ml-n2"
                          color="#e74c3c"
                          v-bind="attrs"
                          v-on="on"
                          >mdi-alert-circle-outline</v-icon
                        >
                      </template>
                      <span>{{ message }}</span>
                    </v-tooltip>
                  </template>
                </v-text-field>
                <v-btn
                  depressed
                  class="text-none white--text rounded-r-lg rounded-l-0"
                  color="#3498db"
                  height="38"
                  :disabled="loading"
                  @click="requestOTP"
                  >Kirim Kode</v-btn
                >
              </div>

              <div class="subtitle-2">
                Password Baru
                <small style="color: #e74c3c"><i>*wajib diisi</i></small>
              </div>
              <v-text-field
                dense
                solo
                autocomplete="new-password"
                color="#4ab762"
                :type="showPassword ? 'text' : 'password'"
                placeholder="Tulis disini"
                class="rounded-lg mb-3"
                :disabled="loading"
                v-model="form.password"
                :error-messages="validation.password"
                @keyup="validation.password = ''"
                :rules="[
                  (v) => !!v || `Password baru tidak boleh kosong`,
                  (v) =>
                    (v && v.length >= 6) || `Password baru minimal 6 karakter`,
                ]"
              >
                <template v-slot:message="{ message }">
                  <v-tooltip top max-width="250" color="#e74c3c">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="mr-2 ml-n2"
                        color="#e74c3c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>

              <div class="subtitle-2">
                Ulangi Password Baru
                <small style="color: #e74c3c"><i>*wajib diisi</i></small>
              </div>
              <v-text-field
                dense
                solo
                autocomplete="new-password"
                color="#4ab762"
                :type="showPassword ? 'text' : 'password'"
                placeholder="Tulis disini"
                class="rounded-lg mb-3"
                :disabled="loading"
                v-model="form.password_confirm"
                :error-messages="validation.password_confirm"
                @keyup="validation.password_confirm = ''"
                :rules="[
                  (v) => !!v || `Ulangi password baru tidak boleh kosong`,
                  (v) =>
                    (v && v.length >= 6) ||
                    `Ulangi password baru minimal 6 karakter`,
                ]"
              >
                <template v-slot:message="{ message }">
                  <v-tooltip top max-width="250" color="#e74c3c">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="mr-2 ml-n2"
                        color="#e74c3c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
              <v-checkbox
                dense
                class="mt-0 pt-0 checkbox-pwd"
                v-model="showPassword"
                color="#4ab762"
                :label="!showPassword ? 'Show password' : 'Hide password'"
                hide-details
              ></v-checkbox>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-space-between px-4">
            <v-btn
              rounded
              depressed
              class="text-none white--text text--lighten-3 flex-grow-1"
              color="#4ab762"
              @click="doSave"
            >
              <v-icon left>mdi-check-circle-outline</v-icon>Ubah Password
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Password",

  data: () => ({
    breadcrumbs: [
      {
        text: "Dashboard",
        disabled: false,
        href: "/",
      },
      {
        text: "Ubah Password",
        disabled: true,
        href: "/password",
      },
    ],

    showPassword: false,

    loading: false,

    valid: true,
    form: {
      code: "",
      password: "",
      password_confirm: "",
      device: "web",
    },
    validation: {
      code: "",
      password: "",
      password_confirm: "",
      device: "",
    },
  }),

  methods: {
    formReset() {
      Object.assign(this.form, {
        code: "",
        password: "",
        password_confirm: "",
        device: "web",
      });
    },
    validationReset() {
      Object.assign(this.validation, {
        code: "",
        password: "",
        password_confirm: "",
        device: "",
      });
    },
    async doSave() {
      let isValid = this.$refs["form-password"].validate();

      if (isValid) {
        this.$store.state.overlay.show = true;
        this.$store.state.overlay.text = "Menyimpan perubahan password...";

        let response = await this.$post(
          "admin/profile/set_password",
          this.form
        );

        this.$store.state.overlay.show = false;

        if (response.status == 200) {
          this.$store.state.snackbar = {
            show: true,
            text: "Berhasil merubah password",
            color: "#2ecc71",
          };

          this.$refs["form-password"].resetValidation();
          this.formReset();
          this.validationReset();
        } else if (response.status == 412) {
          this.$store.state.snackbar = {
            show: true,
            text: "Cek kembali form Anda",
            color: "#e74c3c",
          };

          Object.assign(this.validation, response.results.data);
        } else if (response.status == 400) {
          this.$store.state.snackbar = {
            show: true,
            text: response.message,
            color: "#e74c3c",
          };
        }
      }
    },

    async requestOTP() {
      this.$store.state.overlay.show = true;
      this.$store.state.overlay.text = "Mengirimkan kode OTP...";

      const response = await this.$get(
        `admin/profile/request_set_password_code`
      );

      this.$store.state.overlay.show = false;

      if (response.status === 200) {
        this.$store.state.snackbar = {
          show: true,
          text: "Berhasil mengirim kode OTP, silahkan cek email Anda",
          color: "#2ecc71",
        };
      } else {
        this.$store.state.snackbar = {
          show: true,
          text: "Gagal mengirim kode OTP.",
          color: "#f39c12",
        };
      }
    },
  },
};
</script>

<style>
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-6 d-block",attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex align-center mb-6"},[_c('v-icon',{attrs:{"size":"15","color":"#4ab762"}},[_vm._v("mdi-home")]),_c('v-breadcrumbs',{staticClass:"pa-0 ml-2",attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"rounded-lg",staticStyle:{"box-shadow":"rgba(0, 0, 0, 0.15) 0px 2px 8px"}},[_c('v-card-title',{staticClass:"subtitle-1 py-2 d-block",staticStyle:{"color":"#4ab762"}},[_vm._v(" Ubah Password "),_c('div',{staticClass:"caption grey--text text--darken-1"},[_vm._v(" Lengkapi form dibawah ini ")])]),_c('v-divider'),_c('v-card-text',{staticStyle:{"height":"calc(100vh - 306px)","overflow-y":"auto"}},[_c('v-alert',{staticClass:"caption",attrs:{"dense":"","text":"","type":"info","color":"#4ab762"}},[_vm._v(" Tekan tombol "),_c('b',[_vm._v("Kirim Kode")]),_vm._v(", lalu cek email Anda dan masukkan kode OTP pada form yang telah tersedia ")]),_c('v-form',{ref:"form-password",attrs:{"lazy-validation":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.doSave.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"subtitle-2"},[_vm._v(" Kode OTP "),_c('small',{staticStyle:{"color":"#e74c3c"}},[_c('i',[_vm._v("*wajib diisi")])])]),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"rounded-l-lg rounded-r-0 mb-3",attrs:{"dense":"","solo":"","autocomplete":"off","color":"#4ab762","type":"text","placeholder":"Tulis disini","disabled":_vm.loading,"error-messages":_vm.validation.code,"rules":[
                  function (v) { return !!v || "Kode OTP tidak boleh kosong"; },
                  function (v) { return (v && v.length == 6) || 'Kode OTP harus 6 karakter'; } ]},on:{"keyup":function($event){_vm.validation.code = ''}},scopedSlots:_vm._u([{key:"message",fn:function(ref){
                var message = ref.message;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"250","color":"#e74c3c"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 ml-n2",attrs:{"size":"22","color":"#e74c3c"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(message))])])]}}]),model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}}),_c('v-btn',{staticClass:"text-none white--text rounded-r-lg rounded-l-0",attrs:{"depressed":"","color":"#3498db","height":"38","disabled":_vm.loading},on:{"click":_vm.requestOTP}},[_vm._v("Kirim Kode")])],1),_c('div',{staticClass:"subtitle-2"},[_vm._v(" Password Baru "),_c('small',{staticStyle:{"color":"#e74c3c"}},[_c('i',[_vm._v("*wajib diisi")])])]),_c('v-text-field',{staticClass:"rounded-lg mb-3",attrs:{"dense":"","solo":"","autocomplete":"new-password","color":"#4ab762","type":_vm.showPassword ? 'text' : 'password',"placeholder":"Tulis disini","disabled":_vm.loading,"error-messages":_vm.validation.password,"rules":[
                function (v) { return !!v || "Password baru tidak boleh kosong"; },
                function (v) { return (v && v.length >= 6) || "Password baru minimal 6 karakter"; } ]},on:{"keyup":function($event){_vm.validation.password = ''}},scopedSlots:_vm._u([{key:"message",fn:function(ref){
              var message = ref.message;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"250","color":"#e74c3c"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 ml-n2",attrs:{"size":"22","color":"#e74c3c"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(message))])])]}}]),model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('div',{staticClass:"subtitle-2"},[_vm._v(" Ulangi Password Baru "),_c('small',{staticStyle:{"color":"#e74c3c"}},[_c('i',[_vm._v("*wajib diisi")])])]),_c('v-text-field',{staticClass:"rounded-lg mb-3",attrs:{"dense":"","solo":"","autocomplete":"new-password","color":"#4ab762","type":_vm.showPassword ? 'text' : 'password',"placeholder":"Tulis disini","disabled":_vm.loading,"error-messages":_vm.validation.password_confirm,"rules":[
                function (v) { return !!v || "Ulangi password baru tidak boleh kosong"; },
                function (v) { return (v && v.length >= 6) ||
                  "Ulangi password baru minimal 6 karakter"; } ]},on:{"keyup":function($event){_vm.validation.password_confirm = ''}},scopedSlots:_vm._u([{key:"message",fn:function(ref){
              var message = ref.message;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"250","color":"#e74c3c"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 ml-n2",attrs:{"size":"22","color":"#e74c3c"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(message))])])]}}]),model:{value:(_vm.form.password_confirm),callback:function ($$v) {_vm.$set(_vm.form, "password_confirm", $$v)},expression:"form.password_confirm"}}),_c('v-checkbox',{staticClass:"mt-0 pt-0 checkbox-pwd",attrs:{"dense":"","color":"#4ab762","label":!_vm.showPassword ? 'Show password' : 'Hide password',"hide-details":""},model:{value:(_vm.showPassword),callback:function ($$v) {_vm.showPassword=$$v},expression:"showPassword"}})],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-space-between px-4"},[_c('v-btn',{staticClass:"text-none white--text text--lighten-3 flex-grow-1",attrs:{"rounded":"","depressed":"","color":"#4ab762"},on:{"click":_vm.doSave}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check-circle-outline")]),_vm._v("Ubah Password ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }